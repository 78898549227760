import React, {useEffect} from 'react'
import CardItem from './CardItem'
import "./styles/Cards.css"
import ImageOne from '../assets/images/test4.jpeg'
import ImageTwo from '../assets/images/test5.jpeg'
import ImageThree from '../assets/images/test6.jpeg'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import Aos from 'aos'
import 'aos/dist/aos.css'



const Cards = ({ intl }) => {

  useEffect( () => {
    Aos.init({})
  }, [])

  const cardsTitle1 = intl.formatMessage({ id: 'CardsTitle1' });
  const cardsTitle2 = intl.formatMessage({ id: 'CardsTitle2' });
  const cardsTitle3 = intl.formatMessage({ id: 'CardsTitle3' });

  const cardsDesc1 = intl.formatMessage({ id: 'CardsDesc1' });
  const cardsDesc2 = intl.formatMessage({ id: 'CardsDesc2' });
  const cardsDesc3 = intl.formatMessage({ id: 'CardsDesc3' });

  return (
      <div className='cards' data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration="1000">
          <h1> <FormattedMessage id="CardsHeader" /> </h1>

          <div className='cards__container' >
            <div className='cards__wrapper'
            data-aos="fade-up"
            data-aos-delay="150"
            data-aos-duration="1000">
              <ul className='cards__items'>
                  <CardItem 
                    src={ImageOne}
                    text={cardsDesc1}
                    label={cardsTitle1}
                    // path='/services'
                    
                  />

                  <CardItem
                    src={ImageTwo}
                    text={cardsDesc2}
                    label={cardsTitle2}
                    // path='/services'
                    
                  />

                  <CardItem
                    src={ImageThree}
                    text={cardsDesc3}
                    label={cardsTitle3}
                    // path='/services'
                    
                  />

              </ul>
            </div>
          </div>
      </div>

  );


}

Cards.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Cards)