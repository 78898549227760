import React, {useEffect} from 'react' 
import styled from 'styled-components'
import Icon1 from '../assets/icons/icon1.svg'
import Icon2 from '../assets/icons/icon2.svg'
import Icon3 from '../assets/icons/icon3.svg'
import { FormattedMessage } from 'react-intl'
import Aos from 'aos'
import 'aos/dist/aos.css'



const ServicesSection = () => {

  useEffect( () => {
    Aos.init({})
  }, [])

  return (
    <ServicesContainer id="services">
      <ServicesH1 
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration="1000"> <FormattedMessage id="ServicesCardHead" /> </ServicesH1>
      <ServicesWrapper
      data-aos="fade-up"
      data-aos-delay="150"
      data-aos-duration="1000">
      <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2> <FormattedMessage id="ServicesCardTitle1" /> </ServicesH2>
          <ServicesP> <FormattedMessage id="ServicesCardDesc1" /> </ServicesP>
        </ServicesCard>
        

        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2> <FormattedMessage id="ServicesCardTitle2" /> </ServicesH2>
          <ServicesP> <FormattedMessage id="ServicesCardDesc2" /> </ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2> <FormattedMessage id="ServicesCardTitle3" /> </ServicesH2>
          <ServicesP> <FormattedMessage id="ServicesCardDesc3" /> </ServicesP>
        </ServicesCard>
        
      </ServicesWrapper>
    </ServicesContainer>
  )
}

export default ServicesSection

const ServicesContainer = styled.div`
  height: 780px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #283951;

  /* @media screen and (max-width: 1224px) {
      height: 1200px;
  } */

  @media screen and (max-width: 980px) {
      height: 1200px;
  }

  @media screen and (max-width: 768px) {
      height: 1780px;
  }
`

const ServicesWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 42px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      padding: 0 20px;
  }
`

const ServicesCard = styled.div`
  background: #edf2f5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 480px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
  max-width: 500px;

  &:hover {
      transform: scale(1.04);
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      background: #dbe2e7;
  }

  @media screen and (max-width: 768px) {
     max-width: 400px;
  }

`

const ServicesIcon = styled.img`
  height: 140px;
  width: 140px;
  margin-bottom: 10px;
`

const ServicesH1 = styled.h1`
  /* font-size: clamp(1.4rem, 2.5vw, 1.8rem);
    margin-bottom: 4rem;
    font-weight: bold;
    color: #fff; */
  font-size: clamp(1.4rem, 3.8vw, 1.8rem);
  text-align: center;
  margin-bottom: 4rem;
  margin-top: 1rem;
  color: #fff;
`

const ServicesH2 = styled.h2`
  font-size: 1.1rem;
  margin-bottom: 18px;
  color: #bd0b27;
`

const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
`