import React from "react"
import { FormattedMessage } from 'react-intl'
import { Layout } from '../components/common/Layout'
import Head from '../components/common/Head'
import SEO from "../components/seo"
import ServicesSection from "../components/ServicesSection"
import Cards from "../components/Cards"
import Scroll from "../components/Scroll"
import InfoSection5 from '../components/InfoSections/InfoSection5'
import { ExplainDataOne, ExplainDataTwo } from "../data/ExplainData"
import "../components/layout.css"
import { servicesObjOne } from "../components/InfoData/Data"

const Services = () => (
  <Layout>
    <Scroll showBelow={250} />
    <SEO title="ServicesPageTitle" />
    <Head title="ServicesPageTitle" />
    <h1 className="services"><FormattedMessage id="Services" /></h1>
    <InfoSection5 {...servicesObjOne} />
    <ServicesSection />
    <Cards />
  </Layout>
)

export default Services
